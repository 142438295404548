import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FEATURE_ACTION_PREFIX } from '../calendar.state';
import { ContributorScheduleDto, QuerySettingsDto } from '@swagger/humanresources';
import { CalendarMode } from '@features/calendar/business';

const ACTION_PREFIX = `${FEATURE_ACTION_PREFIX} - Group Plan`;
export const planGroupActions = createActionGroup({
  source: `${ACTION_PREFIX} Group Plan Actions`,
  events: {
    Request: props<{date: Date, mode: CalendarMode, filter: QuerySettingsDto, skip: number}>(),
    Success: props<{ response: ContributorScheduleDto[], maxHits: number }>(),
    Failure: props<{ error: any }>(),
    clear: emptyProps(),
    setEntities: props<{ entities: ContributorScheduleDto[] }>(),
  },
});

export const planGroupFilterActions = createActionGroup({
  source: `${ACTION_PREFIX} Group Plan Filter Actions`,
  events: {
    Request: emptyProps(),
    Success: props<{ filter: QuerySettingsDto }>(),
    Failure: props<{ error: any }>(),
    setFilter: props<{ filter: QuerySettingsDto }>(),
  },
});
